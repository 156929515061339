import React, { useEffect, useState } from "react";
import SkeletonLoader from "./SkeletonLoader";

type SkeletonImageProps = React.ComponentPropsWithoutRef<"img"> & {
  width?: string;
  height?: string;
};

const SkeletonImage = (props: SkeletonImageProps) => {
  const { src, width = "100%", height = "auto", ...restProps } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const image = new Image();

    image.src = src;

    image.onload = () => {
      setIsLoading(false);
    };

    return () => {
      image.onload = null;
    };
  }, [src]);

  return (
    <SkeletonLoader isLoaded={!isLoading} width={width} height={height}>
      <img {...restProps} src={src} className="image" />
      <style jsx>{`
        .image {
          width: ${width};
          height: ${height};
          object-fit: contain;
          border-radius: inherit;
        }
      `}</style>
    </SkeletonLoader>
  );
};

export default SkeletonImage;
