import React from "react";
import Link from "next/link";
import { array } from "prop-types";
import "lazysizes";
import PartnerStyle from "../../components/styles/partner";
import PartnerBlock from "./PartnerBlock";

const HowToPay = (props) => {
  const { payment } = props;
  return (
    <div className="partner-wrapper">
      <div className="partner-container">
        <div className="partner-single-container">
          <div>
            <h2 className="partner-title">Cara Pembayaran</h2>
            <p className="partner-sub-title">
              Mitra Pembayaran Home Credit Indonesia
            </p>
          </div>
          <PartnerBlock items={payment} />
          <Link href="/cara-pembayaran" as="/cara-pembayaran">
            <a>
              <h3 className="partner-showmore">Lihat Selengkapnya</h3>
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{`
        h3 {
          margin-block-start: 1em;
          margin-block-end: 1em;
        }

        .partner-single-container {
          padding: 12px 16px 12px 16px;
        }

        .partner-block {
          display: flex;
          justify-content: space-between;
          flex-flow: row wrap;
        }
      `}</style>
      <PartnerStyle />
    </div>
  );
};

HowToPay.propTypes = {
  payment: array
};

export default HowToPay;
