import React from "react";
import Swiper from "react-slick";
import ReactSlickCss from "../styles/react-slick-css";
export const SliderBannerMobile = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div className="carousel-wrapper-banner">
      <div className="carousel-container-banner">
        <Swiper {...settings}>{children}</Swiper>
      </div>
      <style jsx global>
        {ReactSlickCss}
      </style>
    </div>
  );
};
