const partner = () => {
  return (
    <style>
      {`
        .partner-block-desktop div img {
          width: 140px;
        }
        .partner-block-desktop div:hover {
          cursor: pointer;
          opacity: 0.5;
        }
        .partner-block-mobile {
          display: none;
          justify-content: space-between;
          flex-flow: row wrap;
          min-height: 15vh;
        }
        .partner-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .partner-container {
          width: 100%;
          max-width: 960px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .partner-title {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 34px;
          text-align: center;
        }
        .partner-sub-title {
          font-size: 21px;
          text-align: center;
        }
        .partner-showmore {
          font-weight: bold;
          margin-bottom: 0;
          color: red;
          font-size: 15px;
          text-align: right;
        }
        @media only screen and (min-width: 320px) {
          .partner-img {
            width: 6em;
          }
        }
        @media only screen and (min-width: 360px) {
          .partner-img {
            width: 7em;
          }
        }
        @media only screen and (min-width: 411px) {
          .partner-img {
            width: 8em;
          }
        }
        @media only screen and (min-width: 480px) {
          .partner-img {
            width: 8em;
          }
        }
        @media only screen and (min-width: 600px) {
          .partner-img {
            width: 13em;
          }
        }
        @media only screen and (min-width: 768px) {
          .partner-img {
            width: 12em;
          }
        }
        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (min-width: 992px) {
          .partner-img {
            width: 15em;
          }
        }
        @media only screen and (min-width: 600px) {
          .partner-sub-title {
            margin-left: 3em;
            margin-right: 3em;
          }
        }
        @media only screen and (max-width: 600px) {
          .partner-sub-title {
            margin-left: 3em;
            margin-right: 3em;
          }
          .partner-title {
            font-size: 18px;
          }
          .partner-sub-title {
            font-size: 12px;
          }
          .partner-showmore {
            font-size: 12px;
          }
        }
      `}
    </style>
  );
};

export default partner;
