import React from "react";
import Flickity from "react-flickity-component";
import SliderStyle from "../../components/styles/slider";

export const SliderUSP = ({ children }) => {
  const flickityOptions = {
    contain: "true",
    prevNextButtons: false,
    cellAlign: "center",
    pageDots: true
  };
  return (
    <div className="carousel-wrapper-usp">
      <div className="carousel-container-usp">
        <Flickity
          options={flickityOptions}
          className={"carousel"}
          elementType={"div"}
        >
          {children}
        </Flickity>
      </div>
      <SliderStyle />
      <style jsx global>
        {`
          .carousel-wrapper-usp {
             {
              /* width: 100%; */
            }
            align-self: center;
          }
          .carousel-container-usp {
            margin-left: 12px;
          }
          .flickity-page-dots {
            bottom: 0px;
          }
        `}
      </style>
    </div>
  );
};
