import React from "react";
import Flickity from "react-flickity-component";
import SliderStyle from "../../components/styles/slider";

export const SliderInstagram = ({ showPageDots = true, children }) => {
  const flickityOptions = {
    contain: "true",
    groupCells: 2,
    cellAlign: "left",
    freeScroll: "true",
    pageDots: showPageDots
  };
  return (
    <div className="carousel-wrapper-ig">
      <div className="carousel-container-ig">
        <Flickity
          options={flickityOptions}
          className={"carousel"}
          elementType={"div"}
        >
          {children}
        </Flickity>
      </div>
      <SliderStyle />
      <style jsx global>
        {`
          .carousel-wrapper-ig {
            max-width: 940px;
            width: 100%;
            align-self: center;
            margin-bottom: 48px;
          }
          .carousel-container-ig {
            margin-left: 12px;
          }
          .flickity-page-dots {
            bottom: -25px;
          }
        `}
      </style>
    </div>
  );
};
