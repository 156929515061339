import React from "react";

type SkeletonLoaderProps = React.ComponentPropsWithoutRef<"div"> & {
  isLoaded: boolean;
  width: string;
  height: string;
};

const SkeletonLoader = (props: SkeletonLoaderProps) => {
  const { children, isLoaded = false, width = "100%", height = "40px" } = props;

  if (isLoaded) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <React.Fragment>
      <div className="skeleton" />
      <style jsx>{`
        .skeleton {
          width: ${width};
          height: ${height};
          animation: skeleton-loading 1s linear infinite alternate;
          border-radius: inherit;
        }

        @keyframes skeleton-loading {
          0% {
            background-color: hsl(200, 20%, 80%);
          }
          100% {
            background-color: hsl(200, 20%, 95%);
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default SkeletonLoader;
