export const Placeholder = () => {
  return (
    <div className="placeholder-wrapper">
      <div className="placeholder-image"></div>
      <style jsx>
        {`
          @keyframes load {
            from {
              left: -100px;
            }
            to {
              left: 100%;
            }
          }
          .placeholder-wrapper::before {
            content: "";
            display: block;
            position: absolute;
            left: -150px;
            min-height: 525.66px;
            width: calc(35vw - 24px);
            background: -webkit-gradient(
              linear,
              left top,
              right top,
              from(transparent),
              color-stop(rgba(255, 255, 255, 0.5)),
              to(transparent)
            );
            background: linear-gradient(
              90deg,
              transparent,
              rgba(255, 255, 255, 0.5),
              transparent
            );
            animation: load 1s infinite;
          }
          .placeholder-image {
            border-radius: 8px;
            width: 100%;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            background-color: #d8d8d8;
            min-height: 525.66px;
          }
        `}
      </style>
    </div>
  );
};
