import Link from "next/link";

type BlogCardProps = {
  thumbnail: string;
  title: string;
  excerpt: string;
  slug?: string;
  externalLink?: string;
  tags: Array<any>;
  landscape?: boolean;
  previousPageTitle?: string;
  previousPageSlug?: string;
};

const BlogCard = ({
  thumbnail,
  title,
  excerpt,
  slug,
  externalLink,
  tags,
  landscape,
  previousPageTitle,
  previousPageSlug,
}: BlogCardProps) => {
  const length = landscape ? 25 : 50;

  return (
    <Link
      href={
        slug
          ? {
              pathname: "/[blog]",
              query: {
                prevTitle: previousPageTitle,
                prevSlug: previousPageSlug,
              },
            }
          : `//${externalLink}`
      }
      as={slug ? `/${slug}` : undefined}
    >
      <a className="card">
        <div className={`card-content-wrapper ${landscape ? "landscape" : ""}`}>
          <img
            style={{ width: landscape ? "120px" : "" }}
            src={thumbnail}
            loading="lazy"
            alt={title}
            className="card-image"
          />
          <div
            className="card-content"
            style={{ marginLeft: landscape ? "16px" : "0" }}
          >
            <div className="card-tag">{tags[0].name ? tags[0].name : tags}</div>
            <div className="card-title">
              {title.length > 100 ? title.slice(0, 100) + " ..." : title}
            </div>
            <div className="card-desc">
              {excerpt.length > length
                ? excerpt.slice(0, length) + " ..."
                : excerpt}
            </div>
          </div>
        </div>
        <style jsx>{`
          .read-button {
            color: var(--color-red);
            font-weight: bold;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 6px;
          }
          .read-button:hover {
            cursor: pointer;
          }
          .card {
            // width: calc(50% - 24px);
            margin: 0 12px 36px;
            display: flex;
            align-content: center;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            // min-height: 94%; /* to overcome blogcard inside blog detail ruined by flickity) */
          }
          .landscape {
            display: flex;
            align-items: center;
          }
          .card-image {
            width: calc(100%% - 24px);
            border-radius: 8px;
            width: 100%;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
          }
          .card-tag {
            font-weight: bold;
            font-size: ${landscape ? "12px" : "13px"};
            color: var(--color-red);
            margin: ${landscape ? "0" : "16px 0 4px"};
          }
          .card-title {
            font-weight: bold;
            font-size: ${landscape ? "12px" : "17px"};
            color: #000000;
          }
          .card-desc {
            font-size: 12px;
            text-align: left;
            margin-top: 4px;
            color: #676767;
          }
          .card-content-wrapper {
            width: 320px;
          }
          @media only screen and (max-width: 611px) {
            .card-tag {
              margin: 8px 0;
            }
            .card {
              // width: calc(100% - 30px);
              margin-bottom: 18px;
            }
            .card:not(:first-child) {
              margin-top: 20px;
            }
            .card-desc {
              margin-top: 8px;
            }
            .card-content-wrapper {
              width: ${landscape ? "300px" : "280px"};
            }
          }
        `}</style>
      </a>
    </Link>
  );
};

export default BlogCard;
