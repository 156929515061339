import { SliderUSP } from "../UserInterface/SliderUSP";
import UseWindowSize from "../UseWindowSize";
import React, { useState, useEffect } from "react";
import "lazysizes";

const uspProps = [
  {
    title: "Pengajuan Cicilan Gampang dan Cepat",
    description: "Cukup KTP dan satu dokumen lainnya",
    img: "/static/images/png/usp/usp_1.png"
  },
  {
    title: "Promo dan Diskon Melimpah",
    description: "Rasakan nikmatnya belanja dengan promo tiap harinya",
    img: "/static/images/png/usp/usp_2.png"
  },
  {
    title: "Pembayaran Super Praktis",
    description:
      "Ga pake pusing. Bisa lewat aplikasi, ATM, atau minimarket terdekat",
    img: "/static/images/png/usp/usp_3.png"
  },
  {
    title: "Produk Pembiayaan Multi-Guna",
    description: "Akses pembiayaan untuk memenuhi kebutuhan kamu",
    img: "/static/images/png/usp/usp_4.png"
  }
];

const UniqueSp = (props) => {
  const { img, description, title } = props;
  return (
    <div className="usp-container">
      <img data-src={img} className="lazyload" alt={title} />
      <div className="usp-title">{title}</div>
      <p>{description}</p>
      <style jsx>{`
        .usp-container {
          width: 20%;
        }
        .usp-container img {
          height: 120px;
        }
        .usp-container .usp-title {
          font-weight: bold;
          font-size: 14px;
        }
        .usp-container p {
          font-size: 14px;
        }
        @media only screen and (max-width: 600px) {
          .usp-container {
            width: 100%;
            height: 230px;
          }
          .usp-title {
            font-weight: bold;
            font-size: 14px;
          }
          .usp-container p {
            font-size: 12px;
            padding: 0 50px 0px;
            text-align: center;
          }
        }
      `}</style>
    </div>
  );
};

const WhyHC = () => {
  const { width } = UseWindowSize();
  const [winSize, setWinSize] = useState(null);

  useEffect(() => {
    setWinSize(width);
  }, []);

  return (
    <div className="why-wrapper">
      <div className="why-container">
        <div className="why-title">Kenapa Home Credit</div>
        {winSize > 600 ? (
          <div className="why-usp-container">
            {uspProps.map((item, key) => (
              <UniqueSp
                img={item.img}
                title={item.title}
                description={item.description}
                key={key}
              />
            ))}
          </div>
        ) : (
          <div className="why-usp-container-mobile">
            <SliderUSP>
              {uspProps.map((item, key) => (
                <UniqueSp
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  key={key}
                />
              ))}
            </SliderUSP>
          </div>
        )}
      </div>
      <style jsx>{`
        .why-wrapper {
          display: flex;
          justify-content: center;
          padding: 24px 0 24px 0;
          background-color: var(--color-white-softer);
        }
        .why-title {
          font-weight: bold;
          font-weight: bold;
          font-size: 34px;
        }
        .why-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          max-width: var(--container-desktop-size);
          width: 100%;
          text-align: center;
          margin: 16px;
        }
        .why-usp-container {
          display: flex;
          margin: 24px 0px;
          justify-content: space-between;
          align-items: baseline;
        }
        @media only screen and (max-width: 600px) {
          .why-wrapper {
            padding: 0;
          }
          .why-container {
            justify-content: flex-start;
          }
          .why-title {
            font-size: 18px;
          }
          .why-usp-ojk img {
            height: 20px;
          }
          .usp-container-dot {
            margin-top: 50px;
          }
        }
      `}</style>
    </div>
  );
};

export default WhyHC;
