import React from "react";
import PartnerStyle from "../../components/styles/partner";
import SkeletonImage from "../UserInterface/Skeleton/SkeletonImage";

const PartnerBlock = ({ items }) => (
  <>
    <div className="partner-block-desktop">
      {items &&
        items.map((el) => (
          <div className="container-img" key={el.name}>
            <SkeletonImage
              className="partner-img lazyload"
              data-src={el.url}
              src={el.url}
              alt={el.name}
              width="140px"
              height="65px"
            />
          </div>
        ))}
    </div>
    <div className="partner-block-mobile">
      {items &&
        items.slice(0, 6).map((el) => (
          <div className="container-img" key={el.name}>
            <SkeletonImage
              className="partner-img lazyload"
              data-src={el.url}
              src={el.url}
              alt={el.name}
              width="120px"
              height="50px"
            />
          </div>
        ))}
    </div>
    <style jsx>{`
      .partner-block-desktop {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-gap: 10px;
      }
      .partner-block-desktop div {
        transition: 0.3s;
      }

      @media only screen and (max-width: 1024px) {
        .partner-block-desktop {
          grid-template-columns: auto auto auto auto;
        }
      }
      @media only screen and (max-width: 600px) {
        .partner-block-desktop {
          display: none;
        }
      }
      @media only screen and (max-width: 600px) {
        .partner-block-mobile {
          display: flex;
        }
      }
    `}</style>
    <PartnerStyle />
  </>
);

export default PartnerBlock;
