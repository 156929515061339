import React, { useEffect, useState } from "react";
import Link from "next/link";
import UseWindowSize from "../UseWindowSize";
import "lazysizes";
import { SliderBannerMobile } from "../UserInterface/SliderBannerMobile";
import fetch from "../../utils/fetch";
import { Placeholder } from "../UserInterface/Placeholder";

type HeroBanner = {
  codename: string;
};

const BannerImage = ({ image, url, alt, width }) => {
  return url ? (
    <Link href={url}>
      <a>
        <div style={{ width: "100%", height: "auto" }}>
          <img
            src={image + "?w=" + width * 1.75}
            style={{
              width: "100%",
              height: "auto",
              minHeight: "525px",
            }}
            alt={alt}
            className="lazyload"
          />
        </div>
      </a>
    </Link>
  ) : (
    <div style={{ width: "100%", height: "auto" }}>
      <img
        src={image + "?w=" + width * 1.75}
        style={{
          width: "100%",
          height: "auto",
          minHeight: "525px",
        }}
        alt={alt}
        className="lazyload"
      />
    </div>
  );
};

const HeroBanner = ({ codename }: HeroBanner) => {
  const { width } = UseWindowSize();
  const [data, setData] = useState(null);
  const [winSize, setWinSize] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getBanner = async (codename: string) => {
    const { status, data } = await fetch(
      "GET",
      `/kentico/slider`,
      {
        codename: codename,
      },
      true
    );
    if (status == 200) {
      setData(data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBanner(codename);
    setWinSize(width);
  }, []);

  return (
    <>
      {winSize !== null ? (
        <>
          {winSize > 600 ? (
            <div className="banner-desktop">
              {isLoading ? (
                <Placeholder />
              ) : (
                <>
                  <SliderBannerMobile>
                    {data &&
                      data.map((el, key) => (
                        <BannerImage
                          image={el.banner_desktop}
                          url={el.banner_slug}
                          alt={el.banner_desktop_name}
                          width={winSize}
                          key={key}
                        />
                      ))}
                  </SliderBannerMobile>
                </>
              )}
            </div>
          ) : (
            <div className="banner-mobile">
              {isLoading ? (
                <Placeholder />
              ) : (
                <SliderBannerMobile>
                  {data &&
                    data.map((el, key) => (
                      <BannerImage
                        image={el.banner_mobile}
                        url={
                          el.banner_slug_mobile && el.banner_slug_mobile !== ""
                            ? el.banner_slug_mobile
                            : el.banner_slug
                        }
                        alt={el.banner_mobile_name}
                        width={winSize}
                        key={key}
                      />
                    ))}
                </SliderBannerMobile>
              )}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      <style jsx>{`
        .banner-mobile {
          background-color: var(--bg-color-img);
        }
        .banner-desktop {
          background-color: var(--bg-color-img);
          min-height: 44vh;
        }
        .prev,
        .next {
          background: none;
          border: none;
          padding: 0;
          outline: inherit;
          cursor: pointer;
          position: absolute;
          top: 50%;
          width: auto;
          margin-top: -25px;
          padding: 16px;
          color: var(--color-white);
          font-weight: bold;
          font-size: 18px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;
        }
        .next {
          right: 0;
          border-radius: 3px 0 0 3px;
        }
        .prev:hover,
        .next:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
        @media only screen and (max-width: 600px) {
          .banner-mobile {
            display: block;
            min-height: 5vh;
          }

          .prev,
          .next {
            padding: 8px;
          }
        }

        @media only screen and (width: 768px) {
          .banner-desktop {
            min-height: 12vh;
          }
        }

        @media only screen and (width: 1024px) {
          .banner-desktop {
            min-height: 15vh;
          }
        }

        @media only screen and (width: 1200px) {
          .banner-desktop {
            min-height: 19vh;
          }
        }

        @media only screen and (width: 1440px) {
          .banner-desktop {
            min-height: 24vh;
          }
        }
      `}</style>
    </>
  );
};

export default HeroBanner;
