import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { SliderInstagram } from "../UserInterface/SliderInstagram";
import { SliderInstagramMobile } from "../UserInterface/SliderInstagramMobile";
import { env } from "src/utils/env";
import { getImageUrl } from "src/utils/magnolia";
import "lazysizes";

type InstagramCardProps = {
  caption: string;
  image: string;
  post_url: string;
  owner: any;
};
const InstagramCard = ({
  caption,
  image,
  post_url,
  owner,
}: InstagramCardProps) => {
  const { profile_pic_url, full_name } = owner;

  return (
    <a target="_blank" href={post_url} className="instagram-card">
      <div className="instagram-user-placeholder">
        <div className="instagram-user-avatar">
          <img
            data-src={profile_pic_url}
            alt={full_name}
            className="lazyload"
          />
        </div>
        <div className="instagram-user-nickname">
          <h4>{full_name}</h4>
        </div>
      </div>
      <div className="instagram-post-image">
        <img data-src={image} alt={full_name} className="lazyload" />
      </div>
      <div className="instagram-post-text">
        <div>{ReactHtmlParser(caption)}</div>
      </div>
      <style jsx>{`
        .instagram-card {
          min-height: 360px;
          max-width: 220px;
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0 4px 8px 0 rgba(64, 64, 64, 0.1);
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 10px;
          padding: 15px;
          width: 85%;
        }
        .instagram-card {
          display: flex;
          flex-direction: column;
          align-content: flex-start;
        }
        .instagram-user-placeholder {
          display: flex;
          align-content: flex-start;
          flex-direction: row;
          margin-bottom: 8px;
        }
        .instagram-user-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .instagram-user-avatar img {
          height: 32px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .instagram-user-nickname {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .instagram-post-image {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .instagram-post-image img {
          width: 100%;
          object-fit: cover;
          height: 198px;
        }
        .instagram-post-text {
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        @media only screen and (max-width: 600px) {
          .instagram-card {
            width: 80%;
            padding: 15px;
          }
          .instagram-user-avatar img {
            height: 32px;
          }
          .instagram-post-image img {
            height: 200px;
          }
        }
        @media only screen and (width: 768px) {
          .instagram-card {
            min-height: 376px;
            width: 80%;
          }
          .instagram-post-image img {
            height: 220px;
          }
        }
        @media only screen and (width: 1200px) {
          .instagram-card {
            max-height: 490px;
            width: 85%;
          }
          .instagram-post-image img {
            height: 220px;
          }
        }
      `}</style>
    </a>
  );
};

type InstagramFeedProps = {
  title?: string;
  backgroundColor?: string;
  padding?: string;
};

const InstagramFeed = ({
  title,
  backgroundColor,
  padding,
}: InstagramFeedProps) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    const { status, data } = await axios.get(
      `${env.MAGNOLIA_URL}/author/.rest/delivery/instagram`
    );

    // remapping data as is after fetch from magnolia
    const instagrams = data.results.map((item) => ({
      owner: {
        full_name: item.name,
        profile_pic_url: `${env.MAGNOLIA_URL}` + getImageUrl(item.profileImage),
      },
      image: `${env.MAGNOLIA_URL}` + getImageUrl(item.postImages),
      caption: item.caption,
      post_url: item.originalPostUrl,
    }));

    if (status == 200) {
      setData(instagrams);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const loopIgcard = data.map((obj, key) => {
    return <InstagramCard {...obj} key={key} />;
  });

  return (
    <div className="instagram-wrapper">
      {isLoading ? (
        <div />
      ) : (
        <div className="instagram-container">
          <div className="instagram-title">{title}</div>
          <div className="instagram-container-desktop">
            <SliderInstagram>{loopIgcard}</SliderInstagram>
          </div>
          <div className="instagram-container-mobile">
            <SliderInstagramMobile>{loopIgcard}</SliderInstagramMobile>
          </div>
        </div>
      )}
      <style jsx>{`
        .instagram-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${backgroundColor
            ? backgroundColor
            : "var(--color-white-softer)"};
        }
        .instagram-container {
          width: 100%;
          max-width: 960px;
          min-height: 500px;
          display: flex;
          flex-direction: column;
          padding: ${padding ? padding : " 24px 0 24px 0"};
        }
        .instagram-title {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 34px;
          text-align: center;
        }
        .instagram-container-desktop {
          display: flex;
          align-content: flex-start;
          flex-direction: row;
          height: 490px;
        }
        .instagram-container-mobile {
          display: none;
        }
        .instagram-prev,
        .instagram-next {
          background-color: transparent;
          border: none;
          padding: 0;
          outline: inherit;
          cursor: pointer;
          top: 50%;
          width: auto;
          margin-top: 410px;
          padding: 16px;
          color: var(--color-white);
          font-weight: bold;
          font-size: 18px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          user-select: none;
        }
        .instagram-next {
          right: 0;
          border-radius: 3px 0 0 3px;
        }
        @media only screen and (max-width: 600px) {
          .instagram-container {
            min-height: 450px;
          }
          .instagram-container-desktop {
            display: none;
          }
          .instagram-container-mobile {
            display: flex;
            align-content: flex-start;
            flex-direction: row;
          }
        }
      `}</style>
    </div>
  );
};

export default InstagramFeed;
